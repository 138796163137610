
import { defineComponent } from "vue";
import StatusButtons from "@/components/ui/StatusButtons.vue";
import DatepickerInput from "@/components/form/DatepickerInput.vue";
import SearchInput from "@/components/form/SearchInput.vue";
import DefaultButton from "@/components/ui/DefaultButton.vue";
import ParcelListItem from "@/components/parcels/ParcelListItem.vue";
import Modal from "@/components/ui/Modal.vue";
import Pagination from "@/components/ui/Pagination.vue";
import { ParcelsListItem } from "@/models/ParcelsListItem";
import parcelsService from "@/services/parcels.service";
import { PaginationMeta } from "@/models/PaginationMeta";
import Spinner from "@/components/ui/Spinner.vue";
import { ParcelsParams } from "@/models/ParcelsParams";
import _ from "lodash";
import { ParcelsStatus } from "@/models/ParcelsStatus";
import FormInput from "@/components/form/inputs/FormInput.vue";
import countriesService from "@/services/countries.service";
import {
  prepareParcelFormData,
  prepareParcelFormStatus,
} from "@/common/ParcelFormTools";
import { FormElementData } from "@/models/FormElementData";
import DynamicForm from "@/components/form/DynamicForm.vue";
import ActionButton from "@/components/parcels/action-button/ActionButton.vue";
import accountService from "@/services/account.service";
import { AccountDetailsResponse } from "@/models/AccountDetailsResponse";
import { filtersData, prepareFilterParams } from "@/common/FilterTools";
import { clearData, PossibleValues, Values } from "@/common/FormTools";
import { prepareReturnParcelShortFormData } from "@/common/ReturnParcelFormTools";
import { Parcel } from "@/models/Parcel";

interface State {
  parcelFormTitle: string;
  showParcelFormModal: boolean;
  showReturnParcelFormModal: boolean;
  showReturnParcelSuccessModal: boolean;
  editingParcelId: number;
  parcels: Array<ParcelsListItem>;
  isLoading: boolean;
  pagination?: PaginationMeta;
  search: string;
  searchedPhrase: string;
  status: ParcelsStatus;
  dateFrom: string;
  dateTo: string;
  filtersValue: {
    countries: string[];
    suppliers: string[];
    products: string[];
  };
  filtersData: {
    [key: string]: FormElementData;
  };
  parcelFormLoading: boolean;
  parcelFormSaving: boolean;
  parcelFormValidation: unknown;
  parcelFormValue: Values;
  parcelFormData: FormElementData;
  returnParcelFormLoading: boolean;
  returnParcelFormSaving: boolean;
  returnParcelFormValidation: unknown;
  returnParcelFormValue: Values;
  returnParcelFormData: FormElementData;
  returnParcelSuccessId: number;
  checkedParcels: Array<number>;
  isParcelsPageChecked: boolean;
  accountDetails: AccountDetailsResponse & {
    country_id?: number;
  };
}

export default defineComponent({
  name: "ParcelsList",
  components: {
    ActionButton,
    DynamicForm,
    Spinner,
    Pagination,
    Modal,
    ParcelListItem,
    DefaultButton,
    SearchInput,
    DatepickerInput,
    StatusButtons,
    FormInput,
  },
  data(): State {
    return {
      parcelFormTitle: "",
      showParcelFormModal: false,
      showReturnParcelFormModal: false,
      showReturnParcelSuccessModal: false,
      editingParcelId: 0,
      parcels: [],
      isLoading: true,
      pagination: undefined,
      search: "",
      searchedPhrase: "",
      status: ParcelsStatus.ALL,
      dateFrom: "",
      dateTo: "",
      filtersValue: {
        countries: [],
        suppliers: [],
        products: [],
      },
      filtersData: filtersData,
      parcelFormLoading: true,
      parcelFormSaving: false,
      parcelFormValidation: {},
      parcelFormValue: {},
      parcelFormData: {
        id: "empty-form",
        type: "form",
      },
      returnParcelFormLoading: true,
      returnParcelFormSaving: false,
      returnParcelFormValidation: {},
      returnParcelFormValue: {},
      returnParcelFormData: {
        id: "empty-form",
        type: "form",
      },
      returnParcelSuccessId: 0,
      checkedParcels: [],
      isParcelsPageChecked: false,
      accountDetails: {},
    };
  },
  async mounted() {
    await this.getList();
    this.accountDetails = await this.getAccountDetails();
    this.parcelFormData = await prepareParcelFormData(this.accountDetails);
    this.parcelFormLoading = false;
  },
  methods: {
    async getAccountDetails() {
      let accountDetails = {} as AccountDetailsResponse & {
        country_id?: number;
      };
      await accountService
        .getAccountDetails()
        .then((response) => {
          accountDetails = response.data;
        })
        .catch(() => {
          accountDetails = {};
        });
      await countriesService.get().then((response) => {
        const countryName = _.get(accountDetails, "country");
        if (countryName) {
          accountDetails.country_id =
            _.reduce(
              response.data.countries,
              (countriesObject, countryData) => {
                countriesObject[countryData.name] = countryData.id;
                return countriesObject;
              },
              {} as Record<string, number>
            )[countryName] ?? undefined;
        }
      });
      return _.omit(accountDetails);
    },
    checkParcelsPage() {
      if (this.isParcelsPageChecked) {
        this.checkedParcels = _.union(this.checkedParcels, this.pageParcelsIds);
      } else {
        this.checkedParcels = _.difference(
          this.checkedParcels,
          this.pageParcelsIds
        );
      }
    },
    checkParcel(parcelId: number) {
      if (_.includes(this.checkedParcels, parcelId)) {
        this.checkedParcels = _.without(this.checkedParcels, parcelId);
      } else {
        this.checkedParcels = _.union(this.checkedParcels, [parcelId]);
      }
      this.isParcelsPageChecked = !_.difference(
        this.pageParcelsIds,
        this.checkedParcels
      ).length;
    },
    isParcelChecked(parcelId: number) {
      return _.includes(this.checkedParcels, parcelId);
    },
    async editParcel(parcelId: number) {
      this.parcelFormTitle = "parcels.edit.title";
      this.parcelFormLoading = true;
      this.showParcelFormModal = true;
      this.editingParcelId = parcelId;
      const { value, validation } = await prepareParcelFormStatus(parcelId);
      this.parcelFormValue = value;
      this.parcelFormValidation = validation;
      this.parcelFormLoading = false;
    },
    mapAccountDetailsToParcelForm(): Values {
      const values = {} as Values;
      const addressFields = [
        "address_first_line",
        "address_second_line",
        "city",
        "state",
        "zip_code",
      ];
      const shipperAddress = _.omit({
        company_name: this.accountDetails?.company_name ?? undefined,
        email: this.accountDetails?.contact_email ?? undefined,
        phone: this.accountDetails?.phone?.main ?? undefined,
        country_id: this.accountDetails?.country_id ?? undefined,
        vat: this.accountDetails?.vat ?? undefined,
        eori: this.accountDetails?.eori ?? undefined,
        ..._.pick(this.accountDetails?.main_address, addressFields),
      });
      if (Object.keys(shipperAddress).length) {
        values.shipper_address = shipperAddress as PossibleValues;
      }
      return _.omit(values);
    },
    createNewParcel(): void {
      this.parcelFormTitle = "parcels.create.title";
      this.showParcelFormModal = true;
      this.parcelFormValue = this.mapAccountDetailsToParcelForm();
      this.parcelFormValidation = {};
    },
    parcelFormCancelAction() {
      this.showParcelFormModal = false;
      this.editingParcelId = 0;
    },
    async parcelFormSubmitAction(values: Values) {
      this.parcelFormSaving = true;
      let action;
      if (this.editingParcelId) {
        action = parcelsService.update(this.editingParcelId, clearData(values));
      } else {
        action = parcelsService.save(clearData(values));
      }
      await action
        .then(() => {
          this.showParcelFormModal = false;
          this.getList();
        })
        .catch((error) => {
          this.parcelFormValidation = error?.response?.data?.details ?? {};
          this.parcelFormValue = values;
          document.getElementsByClassName("modal__content")[0].scrollTo(0, 0);
        });
      this.parcelFormSaving = false;
    },
    mapParcelDataToReturnParcelForm(parcel: Parcel): Values {
      return {
        parcel_id: parcel.id,
        country_id: parcel.receiver_address?.country?.id,
        supplier_id: parcel?.supplier?.id,
        shipper_reference: parcel.shipper_reference,
      } as Values;
    },
    async createNewReturnParcel(parcel: ParcelsListItem): Promise<void> {
      this.returnParcelFormLoading = true;
      this.showReturnParcelFormModal = true;
      this.returnParcelFormValue = this.mapParcelDataToReturnParcelForm(parcel);
      this.returnParcelFormData = await prepareReturnParcelShortFormData(
        parcel
      );
      this.returnParcelFormValidation = {};
      this.returnParcelFormLoading = false;
    },
    returnParcelCancelAction() {
      this.returnParcelFormLoading = true;
      this.showReturnParcelFormModal = false;
    },
    returnParcelSubmitAction(values: Values) {
      this.returnParcelFormSaving = true;
      parcelsService
        .createReturnParcel(_.get(values, "parcel_id") as number, values)
        .then((response) => {
          this.showReturnParcelFormModal = false;
          if (response.data.id) {
            this.showReturnParcelSuccessModal = true;
            this.returnParcelSuccessId = response.data.id;
          }
        })
        .catch((error) => {
          this.returnParcelFormValidation =
            error?.response?.data?.details ?? {};
          this.returnParcelFormValue = values;
          document.getElementsByClassName("modal__content")[0].scrollTo(0, 0);
        });
      this.returnParcelFormSaving = false;
    },
    searchUpdate(newSearch: string) {
      this.search = newSearch;
    },
    statusUpdate(newStatus: ParcelsStatus) {
      this.status = newStatus;
      this.getList();
    },
    applyStatusParams(
      status: ParcelsStatus,
      params: ParcelsParams
    ): ParcelsParams {
      switch (status) {
        case ParcelsStatus.CREATED:
          params.status_created = true;
          break;
        case ParcelsStatus.ACCEPTED:
          params.status_accepted = true;
          break;
        case ParcelsStatus.FAILED:
          params.with_errors = true;
          break;
        case ParcelsStatus.DELIVERED:
          params.status_delivered = true;
          break;
        case ParcelsStatus.RETURNED:
          params.status_returned = true;
          break;
        case ParcelsStatus.IN_TRANSIT:
          params.status_in_transit = true;
          break;
        case ParcelsStatus.ALL:
        default:
          break;
      }
      return params;
    },
    dateFromUpdate(newDateFrom: string) {
      this.dateFrom = newDateFrom;
      this.getList();
    },
    dateToUpdate(newDateTo: string) {
      this.dateTo = newDateTo;
      this.getList();
    },
    selectFilterUpdate(selectFilterName: string, newValue: string[]) {
      switch (selectFilterName) {
        case "countries":
          this.filtersValue.countries = newValue;
          break;
        case "suppliers":
          this.filtersValue.suppliers = newValue;
          break;
        case "products":
          this.filtersValue.products = newValue;
          break;
      }
      this.getList();
    },
    clearSelectFilters() {
      this.filtersValue = {
        countries: [],
        suppliers: [],
        products: [],
      };
      this.getList();
    },
    changePagination(page: number, perPage: number) {
      return this.getList(page, perPage, true);
    },
    async getList(page = 1, perPage = 10, keepCheckedParcels = false) {
      this.isLoading = true;

      this.searchedPhrase = this.search;

      const params = this.applyStatusParams(
        this.status,
        _.pickBy({
          page: page,
          per_page: perPage,
          search: this.search,
          date_from: this.dateFrom,
          date_to: this.dateTo,
          ..._.pickBy(prepareFilterParams(this.filtersValue)),
        }) as ParcelsParams
      ) as ParcelsParams;

      const { data } = await parcelsService.list(params);
      this.parcels = data.data || [];
      this.pagination = data.meta;

      if (keepCheckedParcels) {
        this.isParcelsPageChecked = !_.difference(
          this.pageParcelsIds,
          this.checkedParcels
        ).length;
      } else {
        this.isParcelsPageChecked = false;
        this.checkedParcels = [];
      }

      this.isLoading = false;
    },
  },
  computed: {
    pageParcelsIds(): Array<number> {
      return _.reduce(
        this.parcels,
        (parcelsIds, parcel) => {
          if (parcel?.id) {
            parcelsIds.push(parcel.id);
          }
          return parcelsIds;
        },
        [] as Array<number>
      ) as Array<number>;
    },
  },
});
